<template>
  <div class="more-menu">
    <div class="control" @click="toggle">
      <MoreIcon :disabled="disabled" />
      <transition name="fade">
        <ul class="options-list" v-if="isOpened">
          <li
            v-for="(item, i) in menuItems"
            :key="i"
            @click="onClickAction(item, rowData)"
          >
            <span v-if="item.type === 'save'"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
                /></svg
            ></span>
            <span v-if="item.type === 'edit'"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"
                /></svg
            ></span>
            <span v-if="item.type === 'delete'"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"
                /></svg
            ></span>

            <span>{{ item.title }}</span>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MoreIcon: () => import(/* webpackPrefetch: true */ "./svg-icons/MoreIcon"),
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    rowData: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpened: false,
    /* disabled: true, */
  }),
  created() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.isOpened = !this.isOpened;
      }
    },
    onClickAction(item, rowData) {
      const selectedData = [item, rowData];
      this.$emit("click", selectedData);
      this.isOpened = false;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpened = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.control {
  position: relative;
  display: flex;
}
ul.options-list {
  top: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #dbdbdb;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 10;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8rem 1.1rem;
    background-color: #fff;
    cursor: pointer;
    transition: 0.3s all ease;
    font-size: 12px;
    svg {
      margin: 3px 8px 0px 0px;
      height: 13px;
      fill: #2196f3;
    }
    &:hover {
      background-color: #2196f3;
      color: #fff;
    }
    &:hover svg {
      fill: #fff;
    }
  }
  li:last-child {
    border-bottom: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  will-change: opacity;
  transform: translateY(30%);
}
.actions {
  ul.options-list {
    right: 0 !important;
  }
}
</style>
