var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "more-menu" }, [
    _c(
      "div",
      { staticClass: "control", on: { click: _vm.toggle } },
      [
        _c("MoreIcon", { attrs: { disabled: _vm.disabled } }),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.isOpened
            ? _c(
                "ul",
                { staticClass: "options-list" },
                _vm._l(_vm.menuItems, function(item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      on: {
                        click: function($event) {
                          return _vm.onClickAction(item, _vm.rowData)
                        }
                      }
                    },
                    [
                      item.type === "save"
                        ? _c("span", [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 20 20"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      item.type === "edit"
                        ? _c("span", [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 20 20"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      item.type === "delete"
                        ? _c("span", [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 20 20"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(item.title))])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }